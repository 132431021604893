.closeBtn{
    border-width: 0;
    outline-color: white;
    outline-width: 0px;
}
.closeBtn:focus{
    border-width: 0;
    outline-color: white;
    outline-width: 0px;
}

.modalPhoto{
  height: auto;
  /* margin: 14px 424px 130px 18px; */
  /* padding: 25px 17px 18px 42.1px; */
  border-radius: 10px;
  background-color: #ffffff;
}
.photoHeader{
    height: 83px;
    border-bottom: 1px #003b71 solid;
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.photoHeader >div{
    font-family: Montserrat;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: center;
    color: #003b71;
}

.photoModalMiddleBody{
    padding: 44px 32px 28px 32px;
    border-bottom: 1px #003b71 solid;
}

.photUploadBox {
    width: 440px;
    height: 449px;
    margin: 14px 49px 27px 32.9px;
    /* padding: 43px 73px 28px 35px; */
    border: solid 1px #6f91b1;
    background-color: #e9f1f8;
  }

  .modal-backdrop{
    /* width: 1071px */
    width: 100% !important;
  }

  .phoTopic{
    margin: 47px 85px 15px 0px;
    font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #003b71;
  }

  .uploadBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  .circlePhoto{
    width: 58px;
    height: 58px;
    border-radius: 58px;
    margin: 0 27px 0 0;
    padding: 13px 8.4px 14px 8px;
    border: solid 1px #79add5;
    background-color: #e9f1f8;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .photoUploadName{
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #003b71;
    width: 265px;
  }

  .photoRemoveIcon{
    width: 48px;
    height: 47px;
    margin: 0 0 0px 38px;
    object-fit: contain;
    border-radius: 4px;
  }

  .uploadBoxInner{
    display: flex;
    align-items: center;
  }

  .tickPhoto{
    object-fit: contain;
    color: #799d4b;
     font-size: 30px !important;
  }

  .cancelButton {
    width: 123px;
    height: 35px;
    margin: 5px 30px 15px 85px;
    padding: 9px 37px 8px 38px;
    border-radius: 23px;
    border: solid 1px #79add5 !important;
    background-color: #e9f1f8 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #0a406b !important;
  cursor: pointer;
  }

  .applyButton {
    width: 123px;
    height: 35px;
    margin: 5px 11px 15px 0px;
    padding: 9px 41px 8px;
    border-radius: 23px;
    background-color: #0a406b !important;
    font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff !important;
  cursor: pointer;
  }

  .footerPhoto{
      display: flex;
      justify-content: flex-end;
      align-items: center;
  }

  .photoUploadIcon{
    width: 117px;
    height: 117px;
    margin: 0 4px 37px 2px;
    object-fit: contain;
    margin-top: 43px;
  }
  .boxInnexPh{
      display: flex;
      align-items: center;
      flex-direction: column;
  }

  .uploadedImg{
    width: 440px;
    height: 440px;
    object-fit: cover;
  }

  .imageBox{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
    border: 2px solid #000,
  }

.leftButton{
  justify-content: flex-end;
}
.rightButton{
  justify-content: flex-start;
}

  @media screen and (max-width: 1345px)and (min-width: 300px) {
    .photUploadBox {
      width: 300px !important;
      height: 300px !important;
      margin: 14px 0px 27px 0px;
    }

    .uploadedImg{
      width: 300px !important;
      height: 300px !important;
    }

    .photoRemoveIcon{
      width: 25px;
      height: 25px;
      margin: 0 0 0px 38px;
      object-fit: contain;
      border-radius: 1px;
    }
  }

  @media screen and (max-width: 900px) and (min-width: 500px) {
    .imageBox{
      width: 500px;
    }
  }

  /* /* @media screen and (max-width: 699px) and (min-width: 500px) {
    .imageBox{
      width: 100%;
    }
  } */

  @media screen and (max-width: 499px) and (min-width: 300px) {
    .imageBox{
      width: 100%;
    }
    .photoUploadName{
      width: 200px;
    }
}

@media screen and (max-width: 299px){
  .photUploadBox {
    width: 100% !important;
    height: 100% !important;
    margin: 14px 0px 27px 0px;
  }

  .uploadedImg{
    width: 100% !important;
    height: 100% !important;
  }
  .imageBox{
    width: 100%;
  }
  .photoRemoveIcon{
    width: 15px;
    height: 15px;
    margin: 0 0 0px 0px;
    object-fit: contain;
    border-radius: 1px;
  }
  .photoUploadName{
    width: 160px;
  }
} 
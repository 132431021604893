.fotter-text {
    font-size: 10px;
}

.appLogo{
    width: 80px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.typo{
    display: flex !important;
    justify-content: center!important;
    align-items: center !important;
    flex-direction: column !important;
}

.logImg{
    width: 100px;
    align-items: center;
    margin-bottom: 5px;
}

.authTopRow{
    width: 100%;
    height: 100px;
}

.authBottomRow{
    width: 100%;
    background-color: #ffffff;
    min-height: 100px;
    height: auto;
    display: flex;
    justify-content: center;
    /* align-items: center;
    padding-top: 10%; */
}

.authFirstCol{
    height: 100px;
    /* background-color: #245cd3; */
}

.authFirstCol > div{
    /* height: 100%; */
    background-color: #FFFFFF;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.authTextInput{
    width: 434px;
    margin: 20px 0 20px 0 !important;
}

.MuiInputBase-input:focus{
    background-color: #FFFFFF !important;
    outline-color: none ;
    fill: none;
    background: transparent;
}

.authButtonStyle{
    width: 434px;
    height: 50px;
    margin: 10px 0 30px !important;
    border-radius: 4px !important;
    background-color: #0084F4 !important;

}

.pwErrorColor{
    color: #f44336 !important;
    color: rgba(0, 0, 0, 0.54);
    margin: 0;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

.foggotPassword{
    font-size:medium;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
}

.foggotPassword:hover{
    cursor: pointer;
    color: #3f51b5;
}

@media screen and (max-width: 500px) and (min-width:350px){
    .authTextInput{
        width: 300px;
    }

    .authButtonStyle{
        width: 300px;
    }
}


@media screen and (max-width: 349px) and (min-width:200px){
    .authTextInput{
        width: 200px;
        margin: 5px 0 5px 0 !important;
    }

    .authButtonStyle{
        width: 200px;
      height: 30px;
      margin: 35px 0 5px !important;
    }
}

/* .authLoginSection{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.authCard{
    margin: 100px 50px 20px 0px;
    justify-content: flex-end;
    display: flex;
}

.authButtonBox{
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 60px;

}

.authButton{
    width: 50%;
    color: #fff;
    background-color: #ad3b0d !important;
}




@media screen and (max-width: 1000px) {
    .authCard{
        margin: 100px 20px 20px 50px;
    }
}

@media screen and (max-width: 600px) {
    .authCard{
        margin: 100px 20px 20px 20px;
        justify-content: center;
    }
    .authButton{
        width: 80%;
    }
} */

.camIcon {
 position: absolute;
 background-color:whitesmoke;
 top: 85%;
 left:50%;
 padding: 5px;
 border-radius: 50px;
 appearance: none;
 border: none;
 outline: none;
 background-image: linear-gradient(to right,#FFFFFF 50%, #844FFF 50%);
 background-position: 0%;
 transition: 0.4s;
 background-size: 200px;
}

.camIcon:hover{
 background-position: 100%;
}

.MuiBottomNavigation-root{
    /* width: 100% !important; */
    /* background-color:rgba(255,255,255,0.05) !important; */
    background-color:rgba(255,255,255,0.5) !important;
}

.MuiBottomNavigationAction-root{
    /* width: 100%;
    max-width: none !important;
    min-width: none !important; */
}

.bottom-line{
    /* background-color: rgba(43,43,43,1) !important; */
    background-color: #245cd3 !important;
}

.MuiBottomNavigationAction-root{
    color: rgb(0, 0, 0) !important;
}

.MuiBottomNavigationAction-root.Mui-selected {
    /* color: #3f51b5 !important; */
    /* color: #180e52 !important; */
    color:#ffffff !important;
    padding-top: 6px;
}

.topNavStyle header{
    /* background-color: rgba(43,43,43,1) !important; */
    background-color: #245cd3 !important;
}

.MuiButtonBase-root:focus{
    /* background-color: rgba(245,0,87,0.5); */
}

.modalStyle {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: whitesmoke;
    border:2px solid #000;
    /* box-shadow: 24; */
    min-width: 400px;
}

.MuiDialog-paperWidthSm {
    max-height: 1000px !important;
}
.buttonShowHide{
    display: none !important;
}

@media screen and (max-width: 399px){
    .modalStyle {
        width: 100% !important;
        min-width: 100% !important;
    }
}

@media screen and (max-width: 500px) {
    .MuiBottomNavigation-root{
        /* width: 100% !important; */
        /* background-color:rgba(255,255,255,0.05) !important; */
        background-color:#245cd3 !important;
    }
}

@media screen and (max-width: 959px) {
    .buttonShowHide{
        display: block !important;
    }
}
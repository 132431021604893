.qrCodeStyle{
    width:100%
}

.qrBox{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px;
}

.dashBoardCard{
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
    background-color:springgreen !important
}

.maxSpace{
    margin-bottom: 500px;
   } 

.largeImage{
       /* width: 40% !important;
       height: 40% !important; */
}

.dashBoardFloat{
    margin: 2em;
    position: relative;
}
.dashBoardFloat > button {
    background-color:#245cd3 !important;
}

.initLoader {
    text-align: center;
    /* background-color: rgb(43, 43, 43); */
    background-color:#ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.detail-left-margin{
    padding-top: 2%;
}

.submitButton {
    width: 200px;
    background-color:whitesmoke;;
    appearance: none;
    border: none;
    outline: none;
    background-image: linear-gradient(to right,#FFFFFF 50%, #f50057 50%);
    background-position: 0%;
    transition: 0.5s !important;
    background-size: 400px;
    height: 40px;
    border-radius: 10px !important;
    color: black !important;
}
   
.submitButton:hover{
    background-position: 100%;
    color: white !important;
}

   .count-box{
       position: absolute;
       top: 50%;
       transform: translateY(-50%);
       /* padding:2%; */
       border-radius: 20px;
       background-color: #245cd3;
       width: 2.5vh;
       height: 2.5vh;
       margin:0 1ex 0 1ex;
   }

   .count-box > div {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FFFFFF;
   }

   .otherTextInputBox{
    display: flex;
    justify-content: center;
    align-items: center;
   }

   .otherTextInputBoxForm{
      width: 335px !important;
   }

   .otherSelecter{
        /* width: 335px !important; */
        /* padding: 2px; */
   }

   .detailUserList{
       margin-top: 20px;
   }

   .qrBoxShiowHide{
       display: block;
   }

   .dashBoardFabMobile{
    display:none;
    position: absolute;
    top: 40%;
    left: 80%;
   }

   .dashBoardFabMobile button {
    background-color:#245cd3 !important;
   }

   .lableCardTM{
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
   }

   .cardOuterTM{
     padding: 2%;
   }

   .cardMatch:hover{
       cursor: pointer;
       background:  linear-gradient(
        45deg, #245cd3, transparent) !important;
   }

   .cardMatchConfirmed{
    background:  linear-gradient(
        45deg, #ec4593, transparent) !important;
   }

.nameNewCardNameFont{
    font-size:14px;
    font-weight:400;
    font-family:  sans-serif;
    color: whitesmoke;
    text-align: left;
    padding-left: 10px;
}

.nameNewCardCidFont {
    font-size: 18px;
    font-weight: 800;
    text-align: left;
    padding-left: 10px;
    color: rgb(240, 244, 0);
}

.nameNewCardRankFont{
    font-size:smaller;
    font-weight: 400;
    font-family:  sans-serif;
    color: whitesmoke;
    text-align: left;
    padding-left: 10px;
}

/* new dashboard user card */

.newDashUserCard{
    background-size:cover;
    height: 250px;
    position: relative;
    margin: 5px;
}

.nameCArd{
    height: 120px;
    padding-left:5px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
        5deg, rgb(17, 17, 17), transparent);
}

.nameCArdSelect{
    height: 50px;
    padding-left: 10px;
    position: absolute;
    top: 0;
    right: 0;
    /* background: linear-gradient(
        45deg, rgb(7, 90, 107), transparent);
        padding: 2px,10px ; */
}

.matchCardBox{
    margin-bottom: 20px;
    margin-top: 20px;
    background: linear-gradient( 45deg, rgb(236, 129, 21), transparent);
}

.InnerMatchCardBox{
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding: 0 !important;
}

.classRoundColor{
    color:rgb(10, 71, 13) !important;
    font-weight: bold;
    
}
/*  */
@media screen and (min-width: 2000px) {
    .newDashUserCard{
      
        height: 500px;
       
    }
    
    .nameCArd{
        height: 200px;
        
    }
    .nameCArdSelect{
        height: 100px;
    }
}

@media screen and (max-width: 2000px) and (min-width: 1500px) {
    .newDashUserCard{
      
        height: 325px;
       
    }
    
    .nameCArd{
        height: 120px;
        
    }

    .nameCArdSelect{
        height: 70px;
    }
}


@media screen and (max-width: 1278px) and (min-width: 800px) {
    .newDashUserCard{
      
        height: 425px;
       
    }
    
    .nameCArd{
        height: 150px;
        
    }

    .nameCArdSelect{
        height: 80px;
    }
}
/*  */
   @media screen and (max-width: 959px) {
    .qrBoxShiowHide{
        display:none !important;
    }
    .dashBoardFabMobile{
        display: block;
       }

       .dashBoardFloat{
           position:absolute;
           z-index: 100;
           left:70%;
           top: 20%
       }
   }
   
@media screen and (max-width: 1300px) and (min-width: 956px){
    .topoHeadText{
        font-size: 22px !important;
       }
}

@media screen and (max-width: 956px) and (min-width: 700px){
    .qrCodeStyle{
        width:60%
    }

    /* .largeImage{
        width: 50% !important;
        height: 50% !important;
 } */

 .topoHeadText{
    font-size: 18px !important;
   }
}

@media screen and (max-width: 699px) and (min-width: 500px) {
    .qrCodeStyle{
        width: 75% !important;
    }
    /* .largeImage{
        width: 60% !important;
        height: 60% !important;
 } */

 .topoHeadText{
    font-size: 16px !important;
   }
   
}

@media screen and (max-width: 499px) and (min-width: 300px) {
    .qrCodeStyle{
        width: 55% !important;
    }

    .largeImage{
        width: 40px !important;
        height: 40px !important;
    }

   .maxSpace{
    margin-bottom: 500px;
   }  
   .topoHeadText{
    font-size: 14px !important;
   }

   .otherTextInputBoxForm{
       width: 295px;
   }

   .otherSelecter{
        width: 295px !important;

   }
}

@media screen and (max-width: 299px){
    .largeImage{
        width: 40px !important;
        height: 40px !important;
    }

   .topoHeadText{
        font-size: 10px !important;
   }

   .otherTextInputBoxForm{  
        width: 200px !important;
    }

    .otherSelecter{
        width: 200px !important;
        
    }
}